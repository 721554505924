import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | La Chiva Loka",
  pageDescription:
    "Discover how a Mexican restaurant in rural Iowa cuts credit card fees, increases sales, and turns tables faster with SpotOn’s suite of restaurant POS products.",
  title: "Selling tacos, turning tables",
  subTitle: `How La Chiva Loka turns tacos into profit with SpotOn.`,
  businessName: "La Chiva Loka",
  industry: "Restaurants & hospitality, casual dining",
  location: "Riverside, Iowa",

  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.SERVE,
    spotonProducts.CAPITAL,
    spotonProducts.ASSIST,
  ],
  description:
    "In 2019, Karen and José Cabrera opened La Chiva Loka—an outpost for Mexican American cuisine in rural Iowa. Since partnering with SpotOn, they’ve grown revenue, reduced expenses, and secured investment capital.",
  quoteData: {
    imgName: "la-chiva-loka.png",
    quote: `“I just feel like I'm family. To be honest, I could rely on SpotOn for just about anything. Whenever I turn around, there's something new. SpotOn understands every personal aspect of running a restaurant and has made my life so much easier.”`,
    personName: "Karen Cabrera",
    personTitle: "Owner, La Chiva Loka",
  },
  goal: `To increase profits and efficiency, the Cabreras had three objectives: to minimize their $20,000/year credit card fees, boost sales with faster service, and streamline ordering with technology.`,
  solution: `While SpotOn Assist helped the Cabreras save on expenses, SpotOn Serve handhelds reduced check times and kitchen overload. Also, a $42,000 SpotOn Capital investment allowed for new equipment upgrades.`,
  solutionReadMore: {
    text: `Read more about their story.`,
    url: `https://spoton.com/blog/chiva-loka-spoton-capital-funds/`,
  },
  results: {
    title: `The results`,
    stats: [
      { title: `$8,000`, description: `savings in credit card fees` },
      {
        title: `50%`,
        description: `increase in weekly sales`,
      },
      { title: `<3min.`, description: `wait time for SpotOn support` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Coordinating a Caribbean restaurant",
    imageName: "freetown-road-project.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-28",
    href: "/case-studies/freetown-road-project",
    goals: [
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Attracting more fast-casual vegan customers",
    imageName: "majani-soulful-vegan-cuisine.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-07",
    href: "/case-studies/majani-soulful-vegan-cuisine",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "Boosting traffic to a BBQ business",
    imageName: "chicago-culinary-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-02",
    href: "/case-studies/chicago-culinary-kitchen",
    goals: [
      resourceGoals.REACH_NEW_CUSTOMERS,
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
];
